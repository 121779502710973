.forGot-Main-Div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa 0% 0% no-repeat padding-box;
  height: 600px;
}
.forgot-Main-Row {
  height: 400px;
  width: 40%;
}
.text-Col{
  height: 100px;
}
.want-To-Be-Text {
  font-size: 22px;
  color: #156985;
  text-transform: uppercase;
  opacity: 1;
}
.leave-Your-Res {
  color: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 12px;
}
.inputs-Col{
  margin-bottom: 100px;
}
@media screen and (max-width: 600px) {
  .forgot-Main-Row {
    width: 95%;
  }
}