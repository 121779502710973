.card-Main-Div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  height: 100px;
  box-shadow: 0px 0px 2px #0000001a;
  border-radius: 2px;
  margin-bottom: 20px;
  background-color: white;
}
.dot-Main-Div {
  width: 10%;
}
.pointer {
  height: 10px;
  width: 23%;
  background-color: #d63229;
  border-radius: 18px;
}
.admin-Name-Information-Text-Main-Div {
  border: solid none;
  width: 70%;
  margin-top: 10px;
}
.user-Name-Text-Div {
  border: solid none;
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.name-Text {
  text-transform: uppercase;
  color: #1b283f;
  font-weight: 500;
}
.number-Text {
  text-transform: uppercase;
  color: #1b283f;
  font-weight: 500;
}
.user-Infor-Text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid none;
  width: 80%;
}
.admin-User-Image {
  background: #f64e60;
  height: 50px;
  width: 50px;
  border-radius: 2px;
}
.admin-Uaser {
  height: 50px;
  width: 50px;
}
@media screen and (max-width: 600px) {
  .card-Main-Div {
    width: 95%;
    height: 120px;
    margin-left: 3px;
  }
}
