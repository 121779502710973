.buttons {
  /* width: 50%; */
  height: 55px;
  background: #156985 0% 0% no-repeat padding-box;
  color: white;
  text-transform: uppercase;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 3px;
  font-weight: 450;
  font-size: 15px;
}
