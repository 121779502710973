.nav-Bar-Main-Div {
  height: 59px;
  width: 99%;
}
.nav-Bar-Main-Row {
  background: #eef0f8;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  justify-content: flex-end;
}
.nav-Bar-Col {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: solid none;
}
.flag-Imag {
  height: 30px;
  width: 40px;
}
.user-Name-Text {
  margin-top: 10px;
}
.user-Image {
  height: 30px;
  width: 30px;
}

/* @media screen and (max-width: 600px) {
  .nav-Bar-Main-Div {
    width: 95%;
  }
} */
