.main-Div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  height: 600px;
}
.container-Row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 40%;
}
.containerCol {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.headin-Main-Div {
  width: 88%;
}
.headin-Welcome {
  color: #1b283f;
  font-size: 30px;
  font-weight: 550;
}
.new-Create-Text-Div {
  display: flex;
}
.new-Here-Text {
  color: #1b283f;
  opacity: 0.5;
}
.create-An-Text {
  text-decoration: none;
  font-size: 16px;
  color: #156985;
  font-weight: 500;
  font-family: normal normal normal 16px/19px Roboto;
}
.input-Col {
  text-align: right;
  margin-bottom: 50px;
}
.for-Got-Pass-Text {
  text-transform: uppercase;
  text-decoration: none;
  color: #156985;
  font-weight: 500;
  font-size: 15px;
}
.btn-Col{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .container-Row {
    width: 95%;
    border: none;
  }
  .headin-Main-Div {
    width: 100%;
  }
  .headin-Welcome {
    font-size: 25px;
  }
}
