input {
  padding-left: 10px;
  height: 50px;
  border: 0.5px solid #d6d6e0;
  border-radius: 3px;
  color: #80808f;
  opacity: 1;
  background-color: #fafafa;
  outline: none;
  cursor: pointer;
}
