.sign-In-Main-Div {
  display: flex;
  justify-content: center;
  background: #fafafa 0% 0% no-repeat padding-box;
  height: 900px;
}
.sign-In-Main-Row {
  height: 400px;
  width: 40%;
}
.signin-want-To-Be-Col{
  height: 100px;
  border: solid none;
}
.want-To-Be-Text {
  font-size: 22px;
  color: #156985;
  text-transform: uppercase;
  opacity: 1;
}
.leave-Your-Res {
  color: #000000;
  opacity: 1;
  font-weight: 500;
  font-size: 12px;
}
.inputs-Col{
  margin-bottom: 100px;
}
.by-Proceeding-Text {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  /* font: normal normal normal 12pt/18pt Roboto; */
  color: #000000;
}
.privacy-Text {
  text-align: center;
  /* font: normal normal normal 12pt/18pt Roboto; */
  color: #156985;
  cursor: pointer;
}
.already-Have-Text {
  text-align: center;
  /* font: normal normal medium 16pt/19pt Gilroy; */
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 500;
}
.login-Text {
  text-decoration: none;
  color: #156985;
}
@media screen and (max-width: 600px) {
  .sign-In-Main-Row  {
    width: 95%;
  }
  .signin-want-To-Be-Col{
    height: 120px;
  }
}